import { ApiClient } from "../../../../../common/utils/axiosConfig";
import { useState } from "react";
import { AxiosResponse } from "axios";

export interface MetricsResp {
  name: string;
  value: number;
}

export const useMetrics = () => {
  const defaultMetrics: MetricsResp[] = [
    {
      value: 8949,
      name: "Deploys",
    },
    {
      value: 82,
      name: "Databases",
    },
    {
      value: 274,
      name: "Instances",
    },
    {
      value: 185,
      name: "Services",
    },
  ];

  const [metric, setMetric] = useState<MetricsResp[]>(defaultMetrics);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { get } = ApiClient();
  const path = "/b1/microservices/public/metrics";

  const getMetrics = async () => {
    setLoading(true);
    try {
      const response: AxiosResponse<MetricsResp[]> = await get(path);
      const data: MetricsResp[] = response.data;
      if (Array.isArray(data)) setMetric(data);
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    getMetrics,
    metric,
    error,
    loading,
  };
};
