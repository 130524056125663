import React from "react";
import { EIcons } from "../../common/enums/icons";
import { Mark } from "../../common/components/Mark/Mark";
import { CactusSvgLogo } from "./components/CactusSvgLogo/CactusSvgLogo";
import {
  IconCarrousel,
  LandingIconsCarrousel,
} from "./components/LandingIconsCarrousel/LandingIconsCarrousel";
import {
  box,
  LandingIconsContainer,
} from "./components/LandingIconsContainer/LandingIconsContainer";
import { LandingMetrics } from "./components/LandingMetrics/LandingMetrics";
import { useButtonsColors } from "./hooks/useButtonsColors";
import { Elinks } from "../../common/enums/links";
import { useMetrics } from "./components/LandingMetrics/hooks/useMetrics";
import { useEffect } from "react";
import { Diagram, IDiagramItem } from "../../common/components/Diagram/Diagram";
import { LandingIconsVerticalContainer } from "./components/LandingIconsVerticalContainer/LandingIconsVerticalContainer";
import { useLottie } from "lottie-react";
import data from "../../../assets/animations/data.json";
import { PartnersSection } from "./components/PartnersSection/PartnersSection";
import { clickOnLoginButton } from "./utils/events";
import FloatingButtons from "../../common/components/FloatingButtons/FloatingButtons";
import { WhatsAppButton } from "./components/WhatsAppButton/WhatsAppButton";
import { TryItSection } from "./components/TryItSection/TryItSection";

export const LandingPage = () => {
  const { metric, getMetrics } = useMetrics();

  useEffect(() => {
    getMetrics();
    // eslint-disable-next-line
  }, []);

  const box: box[] = [
    {
      Icon: EIcons.microservice,
      title: "Microservices",
      desc: "Manage and deploy microservices.",
    },
    {
      Icon: EIcons.https,
      title: "Domains",
      desc: "Link domains and certificates easily.",
    },
    {
      Icon: EIcons.environment,
      title: "Environments",
      desc: "Create multiple environments for services.",
    },
  ];

  const boxWithBackground: box[] = [
    {
      Icon: EIcons.monolithic,
      title: "Apps",
      desc: "Manage and deploy monolithic apps and external services.",
    },
    {
      Icon: EIcons.resource,
      title: "Resources",
      desc: "Manage all kinds of databases and link them to microservices.",
    },
    {
      Icon: EIcons.frontend,
      title: "Frontends",
      desc: "Set up and deploy frontends with any language.",
    },
  ];

  const boxMobile1: box[] = [
    {
      Icon: EIcons.microservice,
      title: "Microservices",
      desc: "Manage and deploy microservices.",
    },
    {
      Icon: EIcons.environment,
      title: "Environments",
      desc: "Create multiple environments for services.",
    },
    {
      Icon: EIcons.resource,
      title: "Resources",
      desc: "Manage all kinds of databases and link them to microservices.",
    },
  ];

  const boxMobile2: box[] = [
    {
      Icon: EIcons.https,
      title: "Domains",
      desc: "Link domains and certificates easily.",
    },
    {
      Icon: EIcons.monolithic,
      title: "Apps",
      desc: "Manage and deploy monolithic apps and external services.",
    },
    {
      Icon: EIcons.frontend,
      title: "Frontends",
      desc: "Set up and deploy frontends with any language.",
    },
  ];

  const iconsToCarrousel: IconCarrousel[] = [
    {
      icon: EIcons.java,
      text: "Java",
    },
    {
      icon: EIcons.rust,
      text: "Rust",
    },
    {
      icon: EIcons.nodejs,
      text: "Node JS",
    },
    {
      icon: EIcons.go,
      text: "Go",
    },
    {
      icon: EIcons.vert,
      text: "Vert",
    },
    {
      icon: EIcons.vue,
      text: "Vue",
    },
    {
      icon: EIcons.react,
      text: "React",
    },
    {
      icon: EIcons.next,
      text: "NextJS",
    },
    {
      icon: EIcons.micronaut,
      text: "Micronaut",
    },
    {
      icon: EIcons.flask,
      text: "Flask",
    },
  ];

  const diagramItems: IDiagramItem[] = [
    {
      icon: EIcons.cloud,
      text: "Link your cloud account",
    },
    {
      icon: EIcons.http,
      text: "Set up domains",
    },
    {
      icon: EIcons.leaf,
      text: "Set up environments",
    },
    {
      icon: EIcons.cube,
      text: "Set up microservices and services",
    },
    {
      icon: EIcons.metrics,
      text: "Set up your resources",
    },
    {
      icon: EIcons.config,
      text: "Set up your frontends",
    },
  ];

  const diagramItemsWithoutIcon1: IDiagramItem[] = [
    {
      title: "Save Money",
      text: "In operation team",
    },
    {
      title: "Focus on the product",
      text: "C4C7US do the rest",
    },
  ];

  const diagramItemsWithoutIcon2: IDiagramItem[] = [
    {
      text: "Speed up Time to market. Decrease IT ETAs. IT from zero to robust.",
    },
    {
      text: "Support expansion. New product lines faster. Evolve faster. ",
    },
    {
      text: "Agile innovation. Improve process and resources use. Support migrations.",
    },
  ];

  const { ChangeLoginColor, ChangeTryItColor, loginColor } = useButtonsColors();

  const options = {
    animationData: data,
    loop: true,
  };

  const { View: DesktopAnimation } = useLottie(options);

  return (
    <>
      <FloatingButtons>
        <WhatsAppButton />
      </FloatingButtons>
      <div className="Landing__Container">
        <div className="Landing__Top">
          <CactusSvgLogo />
          <div
            className="Pointer"
            onMouseEnter={ChangeLoginColor}
            onMouseLeave={ChangeLoginColor}
          >
            <Mark
              newClass="Landing__Login-Button-Mark"
              backgroundColor={loginColor.bg}
              borderColor={loginColor.border}
              shadow="none"
              height="auto"
              width="auto"
              borderRadius="0.5vw"
            >
              <a
                rel="noreferrer"
                href={Elinks.cactusLogin}
                target="_blank"
                style={{ width: "100%" }}
                className="Landing__Login-Button"
                onClick={clickOnLoginButton}
              >
                Login
              </a>
            </Mark>
          </div>
        </div>
        <div className="Landing__ExamplePage-Container">
          <div className="Landing__ExamplePage-Text">
            <TryItSection />
          </div>
          <div className="Landing__Desktop_animation_Container">
            {DesktopAnimation}
          </div>
        </div>
        <div className="Landing__ExamplePage-Container-Mobile">
          <div onMouseEnter={ChangeTryItColor} onMouseLeave={ChangeTryItColor}>
            <TryItSection />
          </div>
        </div>
        <LandingMetrics metric={metric} />
        <PartnersSection />
        <div className="Landing__Solutions-Container">
          <div className="Landing__Text" style={{ marginBottom: "5vw" }}>
            Our Solution
          </div>
          <div className="Landing__Icons-containers-desktop">
            <LandingIconsContainer data={box} />
            <img
              className="Landing__Interception-lines"
              src="assets/img/BackgroundInterceptionLines.png"
              alt="BackgroundInterceptionLines"
            />
            <LandingIconsContainer data={boxWithBackground} />
            <img
              className="Landing__Divisor-lines"
              src="assets/img/BackgroundLines.png"
              alt="BackgroundLines"
            />
          </div>
          <div className="Landing__Icons-img-containers-mobile">
            <div className="Landing__Icons-containers-mobile">
              <LandingIconsVerticalContainer data={boxMobile1}>
                <img
                  src="/assets/img/BrownSplit.png"
                  width="2.5px"
                  alt="BrownSplit"
                />
              </LandingIconsVerticalContainer>
              <LandingIconsVerticalContainer data={boxMobile2}>
                <img
                  src="/assets/img/BrownSplit.png"
                  width="2.5px"
                  alt="BrownSplit"
                />
              </LandingIconsVerticalContainer>
            </div>
            <img
              className="Landing__Background-Lines-Mobile"
              src="/assets/img/BackgroundLinesMobile.png"
              alt="BackgroundLinesMobile"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="Landing__Text" style={{ marginTop: 0 }}>
            Languages and frameworks
          </div>
          <div
            className="Landing__Text"
            style={{ fontWeight: "normal", marginTop: 0 }}
          >
            to develop easily
          </div>
        </div>
        <div>
          <LandingIconsCarrousel IconsList={iconsToCarrousel} />
        </div>
        <div className="Landing__Landing-Page-Divisor-Lines-Header">
          <img
            style={{
              width: "55%",
            }}
            src="assets/img/LandingPageDivisorLines.png"
            alt="Landing Page Divisor Lines"
          />
        </div>
        <div className="Landing__Orange-Split-Container">
          <img
            src="assets/img/OrangeSplit.png"
            alt="OrangeSplit"
            className="Landing__Orange-Split"
          />
        </div>

        <div className="Landing__Landing-Page-Divisor-Lines">
          <div className="Landing__Flow-Diagram-Half">
            <div
              className="Landing__Text Landing__Text-How-it-works"
              style={{
                marginTop: 0,
                marginBottom: "1.5vw",
                letterSpacing: "0.2vw",
              }}
            >
              How it works
            </div>
            <img
              src="assets/img/GreenTitleSplit.png"
              alt="GreenTitleSplit"
              className="Landing__Green-Title-Split"
            ></img>
            <Diagram
              newClass="Landing__Flow-Diagram-Half-left-Marks"
              borderRadius="0"
              width="27vw"
              height="4.2vw"
              borderColor="#809B28"
              haveIcons={true}
              list={true}
              diagramItems={diagramItems}
            >
              <img
                className="Landing__Green-Split"
                width="0.7%"
                height="50%"
                src="assets/img/GreenSplit.png"
                alt="GreenSplit"
              ></img>
            </Diagram>
            <img
              src="assets/img/GreenSplit.png"
              alt="GreenSplit"
              className="Landing__Green-Title-Split"
            ></img>
            <Mark
              newClass="Landing__MVP-Text"
              width="27vw"
              height="4.2vw"
              borderRadius="0.8vw"
              shadow="none"
              backgroundColor="#FFFFFF40"
              borderColor="#FCFFF1BF"
            >
              ✅ Project ready in ⏱ 15 mins!
            </Mark>
            <img
              src="assets/img/GreenTitleSplitInverted.png"
              alt="GreenTitleSplit"
              className="Landing__Green-Title-Split-Inverted"
            ></img>
          </div>
          <div className="Landing__Flow-Diagram-Half">
            <p
              className="Landing__Text"
              style={{ marginTop: 0, marginBottom: 0, letterSpacing: "0.2vw" }}
            >
              Intuitive and fast self-service
            </p>
            <p
              className="Landing__Text"
              style={{
                margin: 0,
                marginBottom: "2vw",
                fontWeight: "normal",
                letterSpacing: "0.2vw",
              }}
            >
              cloud infrastructure Set-up
            </p>
            <p
              className="Landing__Text"
              style={{
                marginBottom: "1vw",
                fontWeight: "normal",
                letterSpacing: "0.2vw",
              }}
            >
              That enables to
            </p>
            <img
              width="55%"
              src="assets/img/LandingPageFlowDiagramBlueDivisorLines.png"
              alt="LandingPageFlowDiagramBlueDivisorLines"
              className="Landing__Page-Flow-Diagram-Blue-Divisor-Lines"
            ></img>
            <img
              src="assets/img/LandingPageFlowDiagramBlueDivisorLinesMobile.png"
              alt="LandingPageFlowDiagramBlueDivisorLines"
              className="Landing__Page-Flow-Diagram-Blue-Divisor-Lines-Mobile"
            ></img>
            <div className="Landing__Flow-Diagram-Right">
              <Diagram
                newClass="Landing__Flow-Diagram-Right-Half"
                borderRadius="1vw"
                width="17.5vw"
                height="6vw"
                borderColor="#1C7A6F"
                haveIcons={false}
                list={false}
                diagramItems={diagramItemsWithoutIcon1}
              >
                <img
                  width="0.6%"
                  height="70%"
                  src="assets/img/BlueSplit.png"
                  alt="GreenSplit"
                ></img>
              </Diagram>
              <Diagram
                newClass="Landing__Flow-Diagram-Right-Half Landing__Flow-Diagram-Right-Half-Right"
                borderRadius="1vw"
                width="17.5vw"
                height="6vw"
                borderColor="#1C7A6F"
                haveIcons={false}
                list={false}
                diagramItems={diagramItemsWithoutIcon2}
              >
                <img
                  width="0.6%"
                  height="50%"
                  src="assets/img/BlueSplit.png"
                  alt="GreenSplit"
                ></img>
              </Diagram>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
