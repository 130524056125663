import axios from "axios";

export const ApiClient = () => {
  const get = async (path: string, params?: any) => {
    const response = await axios.get(path, {
      params: params,
    });

    return response;
  };

  const post = async (path: string, data: any) => {
    const response = await axios.post(path, data);

    return response;
  };

  return {
    get,
    post,
  };
};
