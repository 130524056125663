import React from "react";
import { environments } from "../../../../../../common/config/enviroment";

export const BiaLogo = () => {
  return (
    <a
      className="PartnerSectionCarrousel__slide Landing__No_styled_Anchor"
      href={environments.biaLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="PartnerSection__Medium_Item"
        style={{
          width: "15vw",
        }}
        src="/assets/img/logoBia.png"
        alt="Bia Logo"
      />
    </a>
  );
};
