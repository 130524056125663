export const environments = {
  api: process.env.REACT_APP_API_BASE,
  console: process.env.REACT_APP_CONSOLE_LINK,
  measurementIdC4c7usCom: process.env.REACT_APP_MEASUREMENT_ID_C4C7US_COM,
  summerLink: process.env.REACT_APP_SUMMER_LINK,
  biaLink: process.env.REACT_APP_BIA_LINK,
  bacuLink: process.env.REACT_APP_BACU_LINK,
  vammoLink: process.env.REACT_APP_VAMMO_LINK,
  rurallLink: process.env.REACT_APP_RURALL_LINK,
  lucroLink: process.env.REACT_APP_LUCRO_LINK,
  linndaLink: process.env.REACT_APP_LINNDA_LINK,
};
