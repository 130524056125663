import React from "react";
import { Elinks } from "../../../../common/enums/links";
import { clickOnWhatsappEvent } from "../../../../common/components/Footer/utils/events";

export const WhatsAppButton = () => {
  return (
    <a
      style={{
        height: "50px",
        width: "50px",
        backgroundColor: "#56C67B",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      href={Elinks.whatsapp}
      rel="noreferrer"
      target="_blank"
      onClick={clickOnWhatsappEvent}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69354 11.6178C6.01532 2.90163 16.474 -0.42364 23.219 5.45328C32.8918 13.8804 24.8441 30.3517 12.3025 26.9625C8.87054 26.0335 5.95581 26.8156 3.98468 27.3445C3.81378 27.3903 3.6499 27.4343 3.49365 27.475C2.94525 27.6171 2.5 27.1297 2.5 26.6297C2.5 26.6297 3.65936 22.5419 3.65936 19.7199C3.65936 18.8094 3.55591 18.0141 3.45306 17.2243C3.24615 15.6323 3.04199 14.0627 3.69354 11.6178ZM5.95489 12.2943C7.81219 5.32129 16.1792 2.66108 21.5753 7.36261C29.3133 14.1043 22.8751 27.2814 12.8421 24.57C10.0964 23.8268 7.76459 24.4524 6.18774 24.8755C6.05102 24.9122 5.9201 24.9474 5.79498 24.98C5.35614 25.0937 5 24.7037 5 24.3037C5 24.3037 5.92743 21.0335 5.92743 18.7759C5.92743 18.0476 5.84473 17.4114 5.76263 16.7796C5.59692 15.506 5.43365 14.2503 5.95489 12.2943Z"
          fill="white"
        />
        <path
          d="M19.9997 17.4869V18.9923C20.0003 19.1321 19.9716 19.2704 19.9155 19.3985C19.8594 19.5265 19.7771 19.6414 19.6739 19.7359C19.5707 19.8304 19.4489 19.9023 19.3163 19.9471C19.1836 19.9919 19.0431 20.0085 18.9036 19.9959C17.3564 19.8281 15.8702 19.3005 14.5645 18.4554C13.3496 17.685 12.3196 16.657 11.5477 15.4446C10.6979 14.1355 10.1691 12.645 10.0041 11.094C9.99152 10.9552 10.008 10.8154 10.0526 10.6833C10.0972 10.5513 10.1688 10.4299 10.2629 10.327C10.357 10.2241 10.4716 10.1419 10.5993 10.0856C10.727 10.0293 10.865 10.0002 11.0046 10H12.513C12.757 9.99765 12.9936 10.0839 13.1786 10.2427C13.3636 10.4015 13.4845 10.622 13.5186 10.8631C13.5823 11.3449 13.7004 11.8179 13.8706 12.2732C13.9382 12.4528 13.9529 12.648 13.9128 12.8357C13.8727 13.0233 13.7795 13.1956 13.6443 13.332L13.0058 13.9693C13.7215 15.2256 14.7638 16.2658 16.0226 16.9801L16.6611 16.3428C16.7978 16.2079 16.9704 16.1149 17.1584 16.0749C17.3465 16.0349 17.5421 16.0495 17.722 16.117C18.1782 16.2869 18.6522 16.4047 19.1349 16.4683C19.3791 16.5027 19.6022 16.6254 19.7616 16.8132C19.9211 17.0011 20.0058 17.2408 19.9997 17.4869Z"
          fill="white"
        />
      </svg>
    </a>
  );
};
