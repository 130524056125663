import React from "react";

interface Iprops {
  children: any;
  width: string;
  height: string;
  shadow: string;
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: string;
  minWidth?: string;
  minHeight?: string;
  borderSize?: string;
  newClass?: string;
}

export const Mark = ({
  shadow,
  children,
  backgroundColor = "transparent",
  borderColor = "white",
  borderRadius = "5px",
  borderSize = "1.5px",
  width,
  height,
  minWidth = width,
  minHeight = height,
  newClass = "",
}: Iprops) => {
  return (
    <div
      className={`Mark ${newClass} `}
      style={{
        backgroundColor: backgroundColor,
        border: `${borderSize} solid ${borderColor}`,
        borderRadius: borderRadius,
        display: "flex",
        width: width,
        height: height,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        minWidth: minWidth,
        minHeight: minHeight,
        boxShadow: shadow,
      }}
    >
      {children}
    </div>
  );
};
