import ReactGA from "react-ga4";
import {
  EventsAction,
  EventsCategories,
  EventsName,
} from "../../../enums/googleAnalitycsEvents";

export const clickOnWhatsappEvent = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  ReactGA.event({
    category: EventsCategories.Landing,
    action: EventsAction.CLICK + " " + EventsName.WhatsAppLink,
    label: "One user has clicked on whatsapp link",
    value: 1,
  });
};

export const clickOnLinkedIn = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  ReactGA.event({
    category: EventsCategories.Landing,
    action: EventsAction.CLICK + " " + EventsName.LinkedInLink,
    label: "One user has clicked on linkedin link",
    value: 1,
  });
};
