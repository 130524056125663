import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
const RouteChangeTracker = () => {
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: path });
  }, [location]);
  return <></>;
};
export default RouteChangeTracker;
