import React from "react";
import { Mark } from "../../../../common/components/Mark/Mark";
import { Icons } from "../../../../common/components/Icons/Icons";
import { EIcons } from "../../../../common/enums/icons";

export interface box {
  Icon: EIcons;
  title: string;
  desc: string;
}

interface ILandingIconsContainer {
  data: box[];
}

export const LandingIconsContainer = ({ data }: ILandingIconsContainer) => {
  return (
    <>
      {data && (
        <div className="LandingIconsContainer__Container">
          {data.map((box, i: number) => {
            return (
              <div key={i} className="LandingIconsContainer__Icon-Container">
                <Mark
                  borderSize="3px"
                  shadow="5px 5px 10px rgb(0, 0, 0, 0.25)"
                  borderRadius="50%"
                  borderColor="rgb(128, 155, 40, 50%)"
                  width="9vw"
                  height="9vw"
                  backgroundColor="#161616"
                  minWidth="50px"
                  minHeight="50px"
                >
                  <Icons width="4vw" Icon={box.Icon} />
                </Mark>
                <h1 style={{ fontSize: "2.2vw", margin: 0, padding: 0 }}>
                  {box.title}
                </h1>
                <p
                  style={{
                    maxWidth: "15vw",
                    fontSize: "1.2vw",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {box.desc}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
