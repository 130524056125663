export enum EventsCategories {
  Landing = "Landing",
}

export enum EventsAction {
  CLICK = "click",
  SCROLL = "scroll",
  HOVER = "hover",
  CHANGE = "change",
  SUBMIT = "submit",
  FOCUS = "focus",
  BLUR = "blur",
  KEYDOWN = "keydown",
  KEYUP = "keyup",
  KEYPRESS = "keypress",
  MOUSEOVER = "mouseover",
}

export enum EventsName {
  TryItButton = "try_it_button",
  LoginButton = "login_button",
  SignUpButton = "sign_up_button",
  WhatsAppLink = "whatsapp",
  LinkedInLink = "linkedin",
}
