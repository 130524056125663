import React from "react";
import { Icons } from "../Icons/Icons";
import { EIcons } from "../../enums/icons";
import { Elinks } from "../../enums/links";
import { clickOnLinkedIn, clickOnWhatsappEvent } from "./utils/events";

export const Footer = () => {
  const copyrightText = "© 2022 C4C7US. All right reserved.";

  return (
    <footer>
      <div className="Footer__Container">
        <p className="Footer__C4C7US-Copyright">
          {window.screen.width > 425
            ? copyrightText
            : copyrightText.toUpperCase()}
        </p>
        <p className="Footer__Center-Text">NOOPS FOR EVERYONE</p>
        <div className="Footer__End-Icons">
          <p>Contact us</p>
          <a
            onClick={clickOnWhatsappEvent}
            className="Pointer Number-link-test"
            href={Elinks.whatsapp}
          >
            <Icons
              newClass="Footer__End-Icon"
              width="1.6vw"
              Icon={EIcons.whatsapp}
            />
          </a>
          <a
            href={Elinks.linkedIn}
            rel="noreferrer"
            target="_blank"
            className="Pointer LinkedIn-link-test"
            onClick={clickOnLinkedIn}
          >
            <Icons
              newClass="Footer__End-Icon"
              width="1.6vw"
              Icon={EIcons.linkedin}
            />
          </a>
        </div>
      </div>
    </footer>
  );
};
