import React, { useState } from "react";
import { ApiClient } from "../../../../../common/utils/axiosConfig";
import { environments } from "../../../../../common/config/enviroment";

export interface UserEarlyAccessInfo {
  email: string;
  name: string;
  company: string;
  domain: string;
  phone: Phone;
  created_at: Date;
  updated_at: Date;
}

export interface Phone {
  country_code: string;
  phone: string;
}

export const useTryItSection = () => {
  const [email, setEmail] = useState("");

  const { post } = ApiClient();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const path = "/api/early-access/public/request";
      await post(path, { email });
    } catch (error) {

    } finally {
      window.location.href = environments.console + "/v1/new?email=" + email;
    }
  };

  return {
    email,
    handleEmailChange,
    handleSubmit,
  };
};
