import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "../common/components/Footer/Footer";
import RouteChangeTracker from "../common/components/RouteChangeTracker/RouteChangeTracker";
import { LandingPage } from "../pages/LandingPage/LandingPage";
import { NotFound404 } from "../pages/NotFound404/NotFound404";

export const Router = () => {
  return (
    <div>
      <BrowserRouter>
        <RouteChangeTracker />
        <Routes>
          <Route path="/*" element={<NotFound404 />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
};
