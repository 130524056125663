import React from "react";
import { Router } from "./app/routes/routes";
import ReactGA from "react-ga4";
import { environments } from "./app/common/config/enviroment";

ReactGA.initialize(environments.measurementIdC4c7usCom!);

const App = () => {
  return (
    <div className="App__Container">
      <Router />
    </div>
  );
};

export default App;
