import React from "react";
import { environments } from "../../../../../../common/config/enviroment";

export const LucroLogo = () => {
  return (
    <a
      className="PartnerSectionCarrousel__slide Landing__No_styled_Anchor"
      target="_blank"
      rel="noopener noreferrer"
      href={environments.lucroLink}
    >
      <img
        className="PartnerSection__Large_Item"
        src="assets/img/Lucro.png"
        alt="Lucro_logo"
      />
    </a>
  );
};
