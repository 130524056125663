import React, { useEffect, useState } from "react";

export const AnimatedNumbers = ({ number }: { number: number }) => {
  const [animatedNumber, setAnimatedNumber] = useState(0);

  let i = 0;
  useEffect(() => {
    const interval = setInterval(() => {
      // eslint-disable-next-line
      i += Math.round(number / 10);
      if (i > number) {
        clearInterval(interval);
        setAnimatedNumber(number);
      } else {
        setAnimatedNumber(i);
      }
    }, 55);
    return () => {
      clearInterval(interval);
    };
  }, [number]);

  return <>{animatedNumber}</>;
};
