import React from "react";

interface Props {
  children?: React.ReactNode;
}

const FloatingButtons = ({ children }: Props) => {
  return <div className="FloatingButtons_Container">{children}</div>;
};

export default FloatingButtons;
