import React from "react";

export const CactusSvgLogo = () => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          className="CactusSvgLogo__img"
          src="/assets/img/C4C7US_LOGO_512x512-07.png"
          alt="C4C7US LOGO"
        />
      </div>
    </>
  );
};
