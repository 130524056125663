import React from "react";
import { environments } from "../../../../../../common/config/enviroment";

export const LeopardLogo = () => {
  return (
    <a
      className="PartnerSectionCarrousel__slide Landing__No_styled_Anchor"
      href={environments.vammoLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width="15vw"
        viewBox="0 0 112 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5406 19.5022H7.12L0.0078125 0.570312H4.65285L9.00862 13.1518L13.5006 0.570312H18.0263L10.5392 19.5036L10.5406 19.5022Z"
          fill="white"
        ></path>
        <path
          d="M32.317 19.5007V17.177C31.7946 18.1173 31.0602 18.8119 30.1138 19.2593C29.166 19.7067 27.9556 19.9311 26.4812 19.9311C23.7135 19.9311 21.4022 18.9752 19.5473 17.0648C17.6924 15.1545 16.7656 12.7825 16.7656 9.94745C16.7656 8.61658 16.9987 7.35389 17.4635 6.16079C17.9283 4.96769 18.5925 3.91805 19.4546 3.01187C20.3842 2.01336 21.4177 1.27619 22.5509 0.800375C23.6854 0.324557 24.9618 0.0859375 26.3801 0.0859375C27.7407 0.0859375 28.9118 0.316035 29.8933 0.774809C30.8749 1.23358 31.6654 1.92814 32.2664 2.85705V0.568858H36.5197V19.5007H32.317V19.5007ZM21.2225 9.87927C21.2225 11.4743 21.7477 12.808 22.7966 13.8804C23.8455 14.9528 25.1247 15.4897 26.6342 15.4897C28.0061 15.4897 29.2151 14.9499 30.2584 13.8719C31.3017 12.7938 31.8241 11.5311 31.8241 10.0852C31.8241 8.5484 31.2989 7.22605 30.25 6.11818C29.2011 5.0103 27.9724 4.45778 26.5654 4.45778C25.091 4.45778 23.8315 4.9819 22.7882 6.03296C21.7449 7.0826 21.2225 8.36518 21.2225 9.87927Z"
          fill="white"
        ></path>
        <path
          d="M89.9678 0.558594V19.4933H85.9364V7.76831L78.2021 19.4933L78.1895 19.4805V19.4933H74.1581V7.63621L66.3761 19.4337L66.3368 19.4933H62.3447V7.63621L54.5234 19.4933L54.3928 19.357V19.4933H50.3614V7.76831L42.6272 19.4933L42.6159 19.482V19.4933H38.5859V0.558594H42.6159V12.2751L50.3446 0.558594L50.3614 0.568536V0.558594H54.3928V12.4541L62.2408 0.558594L62.3447 0.62251V0.558594H66.3761V12.197L74.0528 0.558594L74.1581 0.62251V0.558594H78.1895V12.2751L85.9181 0.558594L85.9364 0.569957V0.558594H89.9678Z"
          fill="white"
        ></path>
        <path
          d="M91.7031 9.94816C91.7031 8.61729 91.9446 7.35175 92.4263 6.15297C92.9079 4.95419 93.6086 3.89035 94.5269 2.96002C95.4677 2.00838 96.5293 1.2769 97.7088 0.765571C98.8897 0.255664 100.114 0 101.385 0C102.734 0 103.98 0.227257 105.12 0.680349C106.26 1.13344 107.306 1.81379 108.26 2.71998C109.236 3.67303 109.99 4.77097 110.523 6.01662C111.057 7.26227 111.324 8.53775 111.324 9.84589C111.324 11.2918 111.091 12.6113 110.626 13.8044C110.161 14.9975 109.463 16.077 108.532 17.04C107.603 18.0158 106.541 18.7529 105.35 19.2515C104.16 19.75 102.866 20 101.471 20C100.064 20 98.7816 19.7472 97.6246 19.243C96.4675 18.7387 95.413 17.9803 94.4595 16.9704C93.5524 15.9832 92.8658 14.8967 92.401 13.7093C91.9362 12.5218 91.7031 11.2677 91.7031 9.94816V9.94816ZM96.1951 9.91407C96.1951 11.6696 96.6711 13.0616 97.6246 14.0885C98.578 15.1154 99.8713 15.6296 101.504 15.6296C103.115 15.6296 104.4 15.104 105.359 14.0544C106.318 13.0048 106.797 11.5901 106.797 9.8118C106.797 8.21675 106.294 6.90576 105.29 5.87884C104.286 4.85193 103.024 4.33776 101.504 4.33776C99.9387 4.33776 98.6623 4.85761 97.6751 5.89589C96.688 6.93417 96.1951 8.27356 96.1951 9.91549V9.91407Z"
          fill="white"
        ></path>
      </svg>
    </a>
  );
};
