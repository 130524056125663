export enum EIcons {
  microservice = "microservice",
  https = "https",
  environment = "environment",
  resource = "resource",
  monolithic = "monolithic",
  apiKey = "apiKey",
  frontend = "frontend",
  java = "java",
  rust = "rust",
  nodejs = "nodejs",
  go = "go",
  vert = "vert",
  react = "react",
  next = "next",
  micronaut = "micronaut",
  flask = "flask",
  whatsapp = "whatsapp",
  linkedin = "linkedin",
  vue = "vue",
  cloud = "cloud",
  http = "http",
  config = "config",
  metrics = "metrics",
  cube = "cube",
  leaf = "leaf",
}
