import React from "react";
import { environments } from "../../../../../../common/config/enviroment";

export const SummerLogo = () => {
  return (
    <a
      className="PartnerSectionCarrousel__slide Landing__No_styled_Anchor"
      href={environments.summerLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        className="PartnerSection__Medium_Item"
        viewBox="0 0 149 34"
        fill="none"
        style={{
          width: "15vw",
        }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_113_3669)">
          <path
            d="M0 22.7994H8.73444C8.95722 25.0826 9.98776 26.9664 13.7952 26.9664C16.4369 26.9664 17.9159 25.6732 17.9159 23.7865C17.9159 21.8165 16.7057 21.2791 12.8537 20.6972C3.80739 19.6224 0.761766 16.9353 0.761766 10.4951C0.761766 4.7087 5.50915 0.5 13.1685 0.5C20.8724 0.5 25.2159 3.72442 25.798 10.4851H17.2892C16.9744 8.20182 15.6766 7.03649 13.1685 7.03649C10.705 7.03649 9.45021 8.15584 9.45021 9.76661C9.45021 11.5584 10.4362 12.3645 14.4218 12.9019C22.5282 13.7971 26.6949 15.9022 26.6949 22.8425C26.6949 28.7079 22.0366 33.4986 13.7952 33.4986C4.83793 33.5 0.268774 29.7396 0 22.7994Z"
            fill="#FFFDFD"
          />
          <path
            d="M30.1401 24.8139V9.55249H38.1573V23.5221C38.1573 26.1646 39.1878 27.4635 41.4717 27.4635C43.8907 27.4635 45.4588 26.0266 45.4588 23.0292V9.55249H53.476V32.9741H45.4588V29.3358C44.204 31.7541 41.9201 33.5028 38.0236 33.5028C33.499 33.5 30.1401 30.7239 30.1401 24.8139Z"
            fill="#FFFDFD"
          />
          <path
            d="M58.2205 9.55247H66.2377V13.1275C67.4925 10.8888 69.956 8.91736 73.5837 8.91736C76.6739 8.91736 78.8241 10.2609 79.9438 12.8128C81.956 10.0827 84.7357 8.91736 87.6922 8.91736C92.1263 8.91736 95.3501 11.7912 95.3501 17.6494V32.9626H87.3329V18.9929C87.3329 16.6192 86.1687 15.4553 84.3318 15.4553C82.1759 15.4553 80.7486 16.8433 80.7486 19.3953V32.9626H72.822V18.9929C72.822 16.6192 71.6578 15.4553 69.8209 15.4553C67.6649 15.4553 66.2377 16.8433 66.2377 19.3953V32.9626H58.2205V9.55247Z"
            fill="#FFFDFD"
          />
          <path
            d="M98.6616 21.545V21.1915C98.6616 13.6693 104.267 8.92175 111.337 8.92175C117.743 8.92175 123.251 12.5945 123.251 21.0119V23.117H106.769C106.994 26.2063 108.782 28.0024 111.656 28.0024C114.298 28.0024 115.418 26.794 115.731 25.2263H123.255C122.537 30.5098 118.463 33.5101 111.386 33.5101C104.037 33.5 98.6616 29.2023 98.6616 21.545ZM115.458 18.5893C115.324 15.7686 113.936 14.2024 111.337 14.2024C108.918 14.2024 107.262 15.7686 106.859 18.5893H115.458Z"
            fill="#FFFDFD"
          />
          <path
            d="M139.561 26.0411C139.561 23.4877 141.523 21.4358 144.214 21.4358C146.945 21.4358 149.003 23.4877 149.003 26.0411C149.003 28.5945 146.95 30.6464 144.214 30.6464C141.52 30.6464 139.561 28.5945 139.561 26.0411Z"
            fill="#FFFDFD"
          />
          <path
            d="M139.603 9.0022C140.897 9.0022 142.003 9.0022 142.756 9.0022V16.4985C138.613 16.385 136.477 17.2558 135.418 19.871C135.064 20.7802 134.889 21.7492 134.903 22.7247V32.9741H126.739V21.9099C126.769 13.6808 132.49 9.02806 139.603 9.0022Z"
            fill="#FFFDFD"
          />
        </g>
        <defs>
          <clipPath id="clip0_113_3669">
            <rect
              width="149"
              height="33"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};
