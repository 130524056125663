import React from "react";
import { EIcons } from "../../enums/icons";
import { Icons } from "../Icons/Icons";
import { Mark } from "../Mark/Mark";

export interface IDiagramItem {
  text: string;
  icon?: EIcons;
  title?: string;
}

interface Iprops {
  borderRadius: string;
  list: boolean;
  diagramItems: IDiagramItem[];
  width: string;
  height: string;
  haveIcons?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  children: any;
  newClass?: string;
}

export const Diagram = ({
  list,
  diagramItems,
  width,
  height,
  backgroundColor = "transparent",
  borderColor = "white",
  haveIcons,
  children,
  borderRadius,
  newClass = "",
}: Iprops) => {
  return (
    <div>
      {diagramItems.map((item, i) => {
        return (
          <div className="Diagram__container" key={i}>
            <Mark
              newClass={newClass}
              borderRadius={borderRadius}
              borderColor={borderColor}
              backgroundColor={backgroundColor}
              width={width}
              height={height}
              shadow="none"
            >
              {!list ? (
                haveIcons ? (
                  <div
                    className="Diagram__Item-Container"
                    style={{ display: "flex" }}
                  >
                    <div className="Diagram__Icon-Container">
                      <Icons
                        newClass="Diagram__icon"
                        width="3vw"
                        Icon={item.icon}
                      />
                    </div>
                    <div>
                      <h1
                        className="Diagram__Item-Title"
                        style={{ margin: 0, fontSize: "1.4vw" }}
                      >
                        {item.title}
                      </h1>
                      <p
                        className="Diagram__Item-Text"
                        style={{ margin: 0, fontSize: "1.2vw" }}
                      >
                        {item.text}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h1
                      className="Diagram__Item-Title"
                      style={{ margin: 0, fontSize: "1.4vw" }}
                    >
                      {item.title}
                    </h1>
                    <p
                      className="Diagram__Item-Title"
                      style={{ margin: 0, fontSize: "1.15vw" }}
                    >
                      {item.text}
                    </p>
                  </div>
                )
              ) : haveIcons ? (
                <div
                  className="Diagram__Item-Container"
                  style={{ display: "flex" }}
                >
                  <div className="Diagram__Icon-Container">
                    <Icons
                      newClass="Diagram__icon"
                      width="3vw"
                      Icon={item.icon}
                    />
                  </div>

                  <div className="Diagram__Item-text-container">
                    <p
                      className="Diagram__Item-Title"
                      style={{ margin: 0, fontSize: "1.15vw" }}
                    >
                      {i + 1}.
                    </p>
                    <h1
                      className="Diagram__Item-Title"
                      style={{ margin: 0, fontSize: "1.4vw" }}
                    >
                      {item.title}
                    </h1>
                    <p
                      className="Diagram__Item-Title"
                      style={{ margin: 0, fontSize: "1.15vw" }}
                    >
                      {item.text}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className="Diagram__Item-text-container"
                  style={{ display: "flex" }}
                >
                  <p
                    className="Diagram__Item-Title"
                    style={{ margin: 0, fontSize: "1.15vw" }}
                  >
                    {i + 1}.
                  </p>
                  <div>
                    <h1
                      className="Diagram__Item-Title"
                      style={{ margin: 0, fontSize: "1.4vw" }}
                    >
                      {item.title}
                    </h1>
                    <p
                      className="Diagram__Item-Title"
                      style={{ margin: 0, fontSize: "1.15vw" }}
                    >
                      {item.text}
                    </p>
                  </div>
                </div>
              )}
            </Mark>
            {i + 1 < diagramItems.length && children}
          </div>
        );
      })}
    </div>
  );
};
