import React from "react";
import { AnimatedNumbers } from "../../../../common/components/AnimatedNumbers/AnimatedNumbers";
import { Mark } from "../../../../common/components/Mark/Mark";
import { title } from "../../../../common/utils/toTitle";
import { MetricsResp } from "./hooks/useMetrics";

interface IProps {
  metric: MetricsResp[];
}

export const LandingMetrics = ({ metric }: IProps) => {
  return (
    <div>
      <div
        style={{
          marginTop: "5vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          position: "relative",
          zIndex: 3,
        }}
      >
        {metric &&
          metric.map((item, i: number) => {
            return (
              <div className="Test-metrics" key={i}>
                <Mark
                  shadow="none"
                  borderRadius="2vw"
                  borderColor="transparent"
                  width="auto"
                  height="11vw"
                  backgroundColor="rgb(128 155 40 / 15%)"
                >
                  <div style={{ textAlign: "center", padding: "1vw 2vw" }}>
                    <p
                      style={{
                        fontSize: "4vw",
                        margin: 0,
                        padding: 0,
                        fontWeight: 700,
                        color: "#809B28",
                      }}
                    >
                      {" "}
                      <AnimatedNumbers number={item.value} />{" "}
                    </p>

                    <p
                      style={{
                        fontSize: "3.5vw",
                        margin: 0,
                        padding: 0,
                        fontWeight: 500,
                        color: "white",
                      }}
                    >
                      {title(item.name)}
                    </p>
                  </div>
                </Mark>
              </div>
            );
          })}
      </div>
    </div>
  );
};
