import ReactGA from "react-ga4";
import {
  EventsAction,
  EventsCategories,
  EventsName,
} from "../../../common/enums/googleAnalitycsEvents";

export const clickOnTryItButton = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  ReactGA.event({
    category: EventsCategories.Landing,
    action: EventsAction.CLICK + " " + EventsName.TryItButton,
    label: "One user has clicked on try it button",
    value: 1,
  });
};

export const clickOnLoginButton = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  ReactGA.event({
    category: EventsCategories.Landing,
    action: EventsAction.CLICK + " " + EventsName.LoginButton,
    label: "One user has clicked on login button",
    value: 1,
  });
};
