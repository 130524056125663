import React from "react";
import { Mark } from "../../../../common/components/Mark/Mark";
import { Icons } from "../../../../common/components/Icons/Icons";
import { box } from "../LandingIconsContainer/LandingIconsContainer";

interface ILandingIconsVerticalContainer {
  data: box[];
  children: any;
}

export const LandingIconsVerticalContainer = ({
  children,
  data,
}: ILandingIconsVerticalContainer) => {
  return (
    <>
      {data && (
        <div className="LandingIconsVerticalContainer__Container">
          {data.map((box, i: number) => {
            return (
              <div
                key={i}
                className="LandingIconsVerticalContainer__Icon-Container"
              >
                <Mark
                  borderSize="2px"
                  shadow="5px 5px 10px rgb(0, 0, 0, 0.25)"
                  borderRadius="50%"
                  borderColor="rgb(128, 155, 40, 50%)"
                  width="17vw"
                  height="17vw"
                  backgroundColor="#161616"
                  minWidth="50px"
                  minHeight="50px"
                >
                  <Icons
                    width="4vw"
                    newClass="LandingIconsVerticalContainer__Icon"
                    Icon={box.Icon}
                  />
                </Mark>
                <h1
                  style={{
                    fontSize: "15px",
                    margin: 0,
                    padding: 0,
                    letterSpacing: "1px",
                  }}
                >
                  {box.title}
                </h1>
                <p
                  style={{
                    width: "22vw",
                    fontSize: "9px",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {box.desc}
                </p>
                {i + 1 < data.length && children}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
