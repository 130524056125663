import React from "react";
import { Mark } from "../../../../common/components/Mark/Mark";
import { Icons } from "../../../../common/components/Icons/Icons";
import { EIcons } from "../../../../common/enums/icons";

export interface IconCarrousel {
  icon: EIcons;
  text: string;
}

interface ILandingIconsCarrousel {
  IconsList: IconCarrousel[];
}

export const LandingIconsCarrousel = ({
  IconsList,
}: ILandingIconsCarrousel) => {
  return (
    <>
      {IconsList && (
        <div className="LandingIconsCarrousel__slider">
          <div className="LandingIconsCarrousel__slide-track">
            {IconsList.map((item, i) => {
              return (
                <div className="LandingIconsCarrousel__slide" key={i}>
                  <Mark
                    newClass="LandingIconsCarrousel__Mark"
                    shadow="5px 5px 10px rgb(0, 0, 0, 0.25)"
                    borderRadius="50%"
                    borderColor="rgb(246, 152, 32, 75%)"
                    borderSize="3px"
                    width="9vw"
                    height="9vw"
                    backgroundColor="#161616"
                  >
                    <Icons
                      Icon={item.icon}
                      width="5vw"
                      newClass="LandingIconsCarrousel__Icon"
                    />
                  </Mark>
                  <p className="LandingIconsCarrousel__text">{item.text}</p>
                </div>
              );
            })}
            {IconsList.map((item, i) => {
              return (
                <div className="LandingIconsCarrousel__slide" key={i}>
                  <Mark
                    newClass="LandingIconsCarrousel__Mark"
                    shadow="5px 5px 10px rgb(0, 0, 0, 0.25)"
                    borderRadius="50%"
                    borderColor="rgb(246, 152, 32, 75%)"
                    borderSize="3px"
                    width="9vw"
                    height="9vw"
                    backgroundColor="#161616"
                  >
                    <Icons
                      Icon={item.icon}
                      width="5vw"
                      newClass="LandingIconsCarrousel__Icon"
                    />
                  </Mark>
                  <p className="LandingIconsCarrousel__text">{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
