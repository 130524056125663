import React from "react";
import { useTryItSection } from "./hooks/useTryItSection";

export const TryItSection = () => {
  const { email, handleEmailChange, handleSubmit } = useTryItSection();

  return (
    <div className="TryItSection__container">
      <h1 className="TryItSection__title">Join our IT</h1>
      <h1 className="TryItSection__title">infrastructure platform.</h1>
      <div className="TryItSection__subtitle_container">
        <span className="TryItSection__subtitle">
          Get a customized, scalable, and affordable cloud solution that suits
        </span>
        <span className="TryItSection__subtitle"> your business needs.</span>
      </div>

      <form className="TryItSection__inputContainer" onSubmit={handleSubmit}>
        <input
          className="TryItSection__input"
          placeholder="Email address"
          onChange={handleEmailChange}
          value={email}
        />
        <button className="TryItSection__button">Suscribe now</button>
      </form>
    </div>
  );
};
