import React from "react";
import { BacuLogo } from "./components/BacuLogo/BacuLogo";
import { BiaLogo } from "./components/BiaLogo/BiaLogo";
import { LeopardLogo } from "./components/LeopardLogo/LeopardLogo";
import { RurallLogo } from "./components/RurallLogo/RurallLogo";
import { SummerLogo } from "./components/SummerLogo/SummerLogo";
import { LinndaLogo } from "./components/LinndaLogo/LinndaLogo";
import { LucroLogo } from "./components/LucroLogo/LucroLogo";

export const PartnersSection = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: "5vw",
      }}
    >
      <div className="Landing__Text" style={{ marginBottom: "3vw" }}>
        Trusted by
      </div>
      <div className="PartnerSectionCarrousel__slider">
        <div className="PartnerSectionCarrousel__slide-track">
          <SummerLogo />
          <BacuLogo />
          <LinndaLogo />
          <LucroLogo />
          <LeopardLogo />
          <BiaLogo />
          <RurallLogo />
          <SummerLogo />
          <BacuLogo />
          <LinndaLogo />
          <LucroLogo />
          <LeopardLogo />
          <BiaLogo />
          <RurallLogo />
        </div>
      </div>
    </div>
  );
};
