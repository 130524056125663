import React from "react";
import { environments } from "../../../../../../common/config/enviroment";

export const LinndaLogo = () => {
  return (
    <a
      className="PartnerSectionCarrousel__slide Landing__No_styled_Anchor"
      target="_blank"
      rel="noopener noreferrer"
      href={environments.linndaLink}
    >
      <img
        className="PartnerSection__Large_Item"
        src="assets/img/Linnda_CMYK_Black_A_Mono.png"
        alt="Linnda_logo"
      />
    </a>
  );
};
