import { useState } from "react";

interface IColor {
  bg: string;
  border: string;
}

export const useButtonsColors = () => {
  const [loginColor, setLoginColor] = useState<IColor>({
    bg: "transparent",
    border: "#ffff",
  });
  const [tryItColor, setTryItColor] = useState<IColor>({
    bg: "transparent",
    border: "#809B28",
  });

  const ChangeLoginColor = () => {
    if (loginColor.bg === "rgb(128, 155, 40, 50%)") {
      setLoginColor({
        bg: "transparent",
        border: "#ffff",
      });
    } else {
      setLoginColor({
        bg: "rgb(128, 155, 40, 50%)",
        border: "#809B28",
      });
    }
  };

  const ChangeTryItColor = () => {
    if (tryItColor.bg === "#809B28") {
      setTryItColor({
        bg: "transparent",
        border: "#809B28",
      });
    } else {
      setTryItColor({
        bg: "#809B28",
        border: "#809B28",
      });
    }
  };

  return {
    ChangeLoginColor,
    ChangeTryItColor,
    loginColor,
    tryItColor,
  };
};
